<template>
  <div>
    <div
      v-if="Object.keys(partner).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <b-card>
        <PartnerToolbar
          v-if="Object.keys(partner).length !== 0"
          :partner="partner"
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer ref="validationPartnerRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('campaigns.campaigntitle')"
                      label-for="i-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-name"
                            v-model="partner.name"
                            :placeholder="$t('campaigns.campaigntitle')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="name"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label="Api url"
                      label-for="i-api_url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="api_url"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LinkIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-api_url"
                            v-model="partner.api_url"
                            placeholder="API URL"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label="Native Protocol Version"
                      label-for="i-native_protocol_version"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          v
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-native_protocol_version"
                          v-model="partner.native_protocol_version"
                          placeholder="Native Protocol Version"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="status"
                        :options="statusesLocale"
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('profile.notes')"
                      label-for="i-notes"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="FileTextIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-notes"
                          v-model="partner.notes"
                          :placeholder="$t('profile.notes')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('partners.phx_rate')"
                      label-for="i-phx_rate"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PercentIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-phx_rate"
                          v-model="partner.phx_rate"
                          placeholder="10"
                          autocomplete="phx_rate"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('sites.country')"
                      label-for="i-country"
                    >
                      <b-form-radio-group
                        v-model="partner.targetings.country.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <div v-if="partner.targetings.country.status !== 'inactive'">
                        <validation-provider
                          #default="{ errors }"
                          name="Country"
                          rules="required"
                        >
                          <v-select
                            id="i-country"
                            v-model="countries"
                            :class="statusVariant(partner.targetings.country.status)"
                            :placeholder="$t('sites.choosecountry')"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="target_countries"
                            label="text"
                            multiple
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div v-else>
                        <v-select
                          id="i-country"
                          v-model="countries"
                          :class="statusVariant(partner.targetings.country.status)"
                          placeholder="Choose the country"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="partner_countries"
                          label="text"
                          multiple
                        />
                      </div>
                    </b-form-group>

                    <b-form-group
                      :label="$t('profile.sites')"
                      label-for="i-sites"
                    >
                      <b-form-radio-group
                        v-model="partner.targetings.site.status"
                        class="mb-1"
                        button-variant="outline-primary"
                        :options="optionsRadioLocale"
                        buttons
                        size="sm"
                        name="radios-btn-default"
                      />
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-sites"
                          v-model="sites"
                          placeholder="IDs"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group v-if="authUserData.role.includes('admin')">
                      <b-form-checkbox
                        v-model="on_image_load"
                        :selected="on_image_load"
                      >
                        {{ $t('campaigns.on_image_load') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="partnerUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ general tab -->

            <b-tab>
              <template #title>
                <feather-icon
                  icon="CheckCircleIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('sites.testing') }}</span>
              </template>
              <b-card>
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h4 class="mb-0">
                      {{ $t('sites.testing') }}
                    </h4>
                  </div>
                  <feather-icon
                    icon="XIcon"
                    size="18"
                    class="cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      label="Site ID"
                      label-for="i-site_id"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-site_id"
                          v-model="site_id"
                          placeholder="Site ID"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="Url"
                      label-for="i-site_url"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-site_url"
                          v-model="site_url"
                          placeholder="Url"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="4"><br>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="checkSite"
                    >
                      <span class="align-middle">{{ $t('Check') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                  <b-col>
                    <div v-if="Object.keys(check_result).length !== 0">
                      <!-- success -->
                      <b-alert
                        variant="success"
                        show
                      >
                        <h4 class="alert-heading">
                          Success
                        </h4>
                      </b-alert>
                      <p><span class="text-success">status code:</span> {{ check_result.status_code }}</p>
                      <p><span class="text-success">request:</span> {{ check_result.request }}</p>
                      <p><span class="text-success">response:</span> {{ check_result.response }}</p>
                      <p><span class="text-success">creatives:</span> {{ check_result.creatives }}</p>
                    </div>
                    <div v-if="check_result.errors">
                      <!-- success -->
                      <b-alert
                        variant="warning"
                        show
                      >
                        <h4 class="alert-heading">
                          Error
                        </h4>
                      </b-alert>
                      <p>{{ check_result.errors.detail }}</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import {
  BAlert,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  // BInputGroupAppend,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BSpinner,
  VBTooltip,
  BFormSelect,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PartnerToolbar from './PartnerToolbar.vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    // BInputGroupAppend,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BFormTextarea,
    vSelect,
    PartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      min,
      required,
      integer,
      partner: {},
      breadcrumbItems: [],
      status: '',
      countries: [],
      sites: [],
      site_id: '',
      site_url: '',
      check_result: '',
      on_image_load: false,
      ...options,
      optionsRadio: [
        { text: 'Inactive', value: 'inactive' },
        { text: 'Active', value: 'active' },
        { text: 'Negative', value: 'negative' },
      ],
    }
  },
  computed: {
    statusesLocale() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`banners.targetings-selects.${o.value}`),
      }))
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.getPartnerData()
  },
  methods: {
    async getPartnerData() {
      const responseData = await useJwt.getPartner(this.$route.params.id)
      this.partner = responseData.data.partner || {}
      this.breadcrumbItems = [
        {
          text: this.$t('RTB partners'),
          to: '/partners/',
        },
        {
          text: this.partner.name,
          to: `/partners/${this.partner._id}/`,
        },
        {
          text: this.$t('Settings'),
          active: true,
        },
      ]
      if (this.partner.status !== '') {
        // const statusText = this.partner.status.charAt(0).toUpperCase() + this.partner.status.slice(1)
        this.status = this.partner.status
      }
      const { countries } = this.partner.targetings.country.params
      if (countries.length) {
        this.countries = countries.map(c => ({ value: c.toLowerCase(), text: c }))
      } else {
        this.countries = null
      }
      const { sites } = this.partner.targetings.site.params
      if (sites.length) {
        this.sites = sites.join(',')
      } else {
        this.sites = null
      }

      this.on_image_load = this.partner.stats_settings ? this.partner.stats_settings.on_image_load : false
    },
    partnerUpdate() {
      this.$refs.validationPartnerRules.validate().then(success => {
        if (success) {
          this.partner.status = this.status

          if (Array.isArray(this.countries)) {
            this.partner.targetings.country.params.countries = this.countries.map(c => (c.text))
          }

          if (typeof this.sites === 'string' && this.sites.includes(',')) {
            this.partner.targetings.site.params.sites = this.sites.split(',').map(s => (s.trim()))
          } else if (typeof this.sites === 'string' && !this.sites.includes(',')) {
            this.partner.targetings.site.params.sites = [this.sites]
          }

          this.partner.stats_settings = { on_image_load: this.on_image_load }

          if (this.partner.phx_rate === '') {
            this.partner.phx_rate = 0
          }

          useJwt.setPartner(this.$route.params.id, { partner: this.partner })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Partner saved',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            }).catch(error => {
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    const field = err.source.pointer.split('/')
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: `Error [${field[field.length - 1]}]`,
                        msg: err.detail,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  })
                }
              }
              if (error.response && (error.response.status === 500 || error.response.status === 400)) {
                this.$store.dispatch(
                  'error-store/showNotify',
                  {
                    title: error.response.statusText,
                    msg: error.message,
                    variant: 'danger',
                  },
                  { root: true },
                )
              }
            })
        }
      })
    },
    async checkSite() {
      if (this.site_url !== '') {
        await useJwt.getPartnerPreview(this.$route.params.id, { site_id: this.site_id, url: this.site_url })
          .then(response => {
            this.check_result = response.data
          })
      }
    },
  },
}
</script>
